import firebase from "firebase";
import React, {
  ChangeEvent,
  FunctionComponent,
  useEffect,
  useState,
} from "react";
import ReactDatePicker from "react-datepicker";
import { Link, RouteComponentProps } from "react-router-dom";
import styled from "styled-components";
import * as Yup from "yup";
import ISite from "../../../../classes/ISite";
import IUser from "../../../../classes/IUser";
import BreadCrumbs from "../../../../components/BreadCrumbs";
import Loading from "../../../../components/Loading";
import useStorage from "../../../../hooks/firebase/storage";
import { useForm } from "../../../../hooks/Form";
import { IEvent } from "../../Events";
import { toast } from "react-toastify";

const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
`;

interface IRouteMatch {
  id: string;
}

const validationSchema = Yup.object<IUser>().shape({
  title: Yup.string().min(2, "Too Short!").required("Required"),
  desc: Yup.string().min(2, "Too Short!").required("Required"),
});

const UserPage: FunctionComponent<RouteComponentProps<IRouteMatch>> = (
  props
) => {
  const [eventPic, setEventPic] = useState<File>();
  const { upload } = useStorage();
  const {
    getFieldProps,
    getTextAreaProps,
    submitData,
    isSaving,
    fieldErrors,
    resetForm,
  } = useForm({
    initialValues: {
      email: "",
    },
    handleSubmit: (values) => save(values),
    validationSchema,
  });

  const { id } = props.match.params;

  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [breadCrumb, setBreadCrumb] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const handleStartDate = (date: Date) => {
    setStartDate(date);
    setEndDate(new Date(date.getTime() + 1 * 60 * 60 * 1000));
  };

  const handleEndDate = (date: Date) => {
    setEndDate(date);
  };

  useEffect(() => {
    (async function LoadData() {
      if (id !== "new") {
        const snapshot = await firebase
          .database()
          .ref(`/events/${id}`)
          .once("value");

        const { title, start, end, desc, image } = snapshot.val() as IEvent;

        const formData = {
          title,
          desc,
        };

        setStartDate(new Date(start));
        setEndDate(new Date(end));

        setBreadCrumb(title);
        resetForm(formData);
      } else {
        setBreadCrumb(id);
      }
      setIsLoading(false);
    })();
  }, []);

  const save = async ({
    title,
    desc,
  }: {
    title: string;
    desc: string;
  }): Promise<void> => {
    let eventId: string | null = id;

    if (id !== "new") {
      await firebase.database().ref(`events/${id}`).update({
        title,
        desc,
        start: startDate.getTime(),
        end: startDate.getTime(),
      });
    } else {
      const key = (
        await firebase.database().ref("events").push({
          title,
          desc,
          start: startDate.getTime(),
          end: startDate.getTime(),
        })
      ).key;

      eventId = key;
    }

    if (eventPic) {
      const filename = upload(
        `events/${eventId}.${eventPic.name.split(".").pop()}`,
        eventPic
      );

      await firebase.database().ref(`events/${eventId}`).update({
        image: filename,
      });
    }

    toast.success("Saved Event");
    if (id === "new") {
      props.history.replace(`/admin/events/`);
    }
  };

  const onFileChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (e && e.target && e.target.files) {
      console.info(e.target.files[0]);
      setEventPic(e.target.files[0]);
    }
  };

  const confirmDelete = async (): Promise<void> => {
    const answer = window.confirm("Are you sure?");

    if (answer) {
      await firebase.database().ref(`events/${id}`).remove();
      toast.success("Event Deleted");
      props.history.replace(`/admin/events`);
    }
  };

  if (isLoading || isSaving) {
    return <Loading />;
  }

  return (
    <>
      <BreadCrumbs>
        <Link to="/admin/">Admin</Link>
        <div>&gt;</div>
        <Link to="/admin/events/">Events</Link>
        <div>&gt;</div>
        <div>{breadCrumb}</div>
      </BreadCrumbs>

      <form onSubmit={submitData}>
        <div className="row">
          <label>Event</label>
          <input
            className="u-full-width"
            type="text"
            placeholder="Title"
            {...getFieldProps("title")}
          />
          {fieldErrors.title && <em>{fieldErrors.title}</em>}
        </div>
        <div className="row">
          <div className="six columns">
            <label>Start Date</label>
            <ReactDatePicker
              selected={startDate}
              onChange={handleStartDate}
              showTimeSelect={true}
              dateFormat="MMMM d, yyyy h:mm aa"
            />
          </div>
          <div className="six columns">
            <label>End Date</label>
            <ReactDatePicker
              selected={endDate}
              onChange={handleEndDate}
              showTimeSelect={true}
              dateFormat="MMMM d, yyyy h:mm aa"
            />
          </div>
        </div>
        {/* <div className="row">
          <label>File</label>
          <input
            className="u-full-width"
            type="File"
            onChange={onFileChange}
            accept="image/*"
          />
        </div> */}
        <div className="row">
          <label>Description</label>
          <textarea
            rows={5}
            className="u-full-width"
            {...getTextAreaProps("desc")}
          />
          {fieldErrors.desc && <em>{fieldErrors.desc}</em>}
        </div>

        <Footer>
          <button className="primary-button">Save</button>
          {id !== "new" && (
            <button className="button" type="button" onClick={confirmDelete}>
              Delete
            </button>
          )}
        </Footer>
      </form>
    </>
  );
};

export default UserPage;
