import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import ForgotPassword from "./ForgotPassword";
import Login from "./Login";

const AuthRouter: React.FC<any> = () => {

  return (
    <>
      <Router>
        <Switch>
          <Route path="/" exact={true} component={Login} />
          <Route path="/reset" exact={true} component={ForgotPassword} />
        </Switch>

      </Router>
    </>
  );

};

export default AuthRouter;
