import React from "react";
import styled from "styled-components";

interface IProps {
  isPaid?: boolean;
}

const Label = styled.span`
  padding: 5px 10px;
  border-radius: 5px;
`;

const IsPaid = styled(Label)`
  background-color: #1eaedb;
  color: #fff;
`;

const Unpaid = styled(Label)`
  background-color: #ff0000;
  color: #fff;
`;

export default (props: IProps) => {
  if (props.isPaid) {
    return <IsPaid>Submitted</IsPaid>;
  }

  return <Unpaid>Unsubmitted</Unpaid>;
};
