import firebase from "firebase";
import React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import Currency from "../../../../components/Currency";
import Loading from "../../../../components/Loading";
import LoadingRow from "../../../../components/LoadingRow";
import SubmittedLabel from "../../../../components/SubmittedLabel";
import IHash from "../../../../utils/IHash";
import { IBillingCycle, IBillingInfo } from "./Interfaces";

const Utilities: React.FunctionComponent<RouteComponentProps> = (props) => {
  const [info, setInfo] = React.useState<IBillingInfo>();
  const [history, setHistory] = React.useState<IHash<IBillingCycle>>();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [ref, setRef] = React.useState<firebase.database.Reference>();

  const buildRow = (row: IHash<IBillingCycle>) => (
    key: string
  ): React.ReactNode => {
    return (
      <tr key={key}>
        <td>
          <Link to={`${props.match.path}${key}`}>{key}</Link>
        </td>
        <td>{row[key].currentReading}</td>
        <td>
          <Currency>{row[key].amount}</Currency>
        </td>
        <td>{<SubmittedLabel isPaid={row[key].isSubmitted} />}</td>
      </tr>
    );
  };

  React.useEffect(() => {
    (async function LoadData() {
      const infoSnap = await firebase
        .database()
        .ref("/billing/info")
        .once("value");

      setInfo(infoSnap.val());
      setIsLoading(false);

      const historySnap = firebase.database().ref("/billing/history");
      historySnap.on("value", (snap) => setHistory(snap.val()));

      setRef(() => historySnap);
    })();

    return () => {
      if (ref) {
        ref.off();
      }
    };
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <div className="row">
        <div className="one-half column">
          <h1>Utilities</h1>
          <h2>Last Reading: {info && info.lastReading}</h2>
        </div>
        <div className="one-half column align-right">
          <Link to={`${props.match.path}new`} className="button button-primary">
            New Cycle
          </Link>
        </div>
      </div>

      <table className="u-full-width">
        <thead>
          <tr>
            <th>Cylcle</th>
            <th>Reading</th>
            <th>Value</th>
            <th>Is Submitted</th>
          </tr>
        </thead>
        <tbody>
          {history ? (
            Object.keys(history).sort().reverse().map(buildRow(history))
          ) : (
            <LoadingRow isLoading={isLoading} columns={4} />
          )}
        </tbody>
      </table>
    </>
  );
};

export default Utilities;
