import firebase from "firebase";
import React from "react";
import Drift from "react-driftjs";
import {
  BrowserRouter as Router,
  NavLink,
  Route,
  RouteComponentProps,
  Switch,
} from "react-router-dom";
import styled from "styled-components";
import IUser from "../../classes/IUser";
import Admin from "./Admin";
import AdminEvents from "./Admin/Events";
import Event from "./Admin/Events/Event";
import Sites from "./Admin/Sites";
import Users from "./Admin/Users";
import User from "./Admin/Users/User";
import Utilities from "./Admin/Utilities";
import Utility from "./Admin/Utilities/Utility";
import BillingHistory from "./Billing";
import BillView from "./Billing/Bill";
import BillPayView from "./Billing/Pay";
import Bryan from "./Bryan";
import Events from "./Events";
import Home from "./Home";
interface IProps {
  userProfile: IUser;
}

const Navbar = styled.nav`
  display: flex;
  justify-content: space-between;
`;
const Navigation = styled.nav`
  display: flex;
  padding: 5px 10px;
  margin: 20px -10px;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 50px;
`;

const HomeScreen = (userProfile: IUser) => (props: RouteComponentProps) => (
  <Home {...props} userProfile={userProfile} />
);

const AppRouter: React.FC<IProps> = (props: IProps) => {
  const { userProfile } = props;

  const SignOut = async (event: React.MouseEvent): Promise<void> => {
    event.preventDefault();
    await firebase.auth().signOut();
  };

  const AdminMenu = userProfile && userProfile.isAdmin && (
    <>
      <NavLink to="/admin/" className="nav-item">
        Admin
      </NavLink>
    </>
  );

  const user = firebase.auth() && firebase.auth().currentUser;
  return (
    <div className="container">
      <Drift
        appId="65bpxx2k5bpg"
        userId={user && user.uid}
        attributes={{ email: userProfile.email, name: userProfile.displayName }}
      />
      <Router>
        <img src="/logo-title.png" width="263" height="66" />
        <Navbar>
          <Navigation>
            <NavLink to="/" className="nav-item" exact={true}>
              Home
            </NavLink>
            <NavLink to="/billing/" className="nav-item">
              Billing
            </NavLink>
            <NavLink to="/events/" className="nav-item">
              Events
            </NavLink>
            {AdminMenu}
          </Navigation>
          <Navigation>
            <a className="nav-item" onClick={SignOut}>
              Sign Out
            </a>
          </Navigation>
        </Navbar>
        <Switch>
          <Route path="/" exact={true} render={HomeScreen(userProfile)} />
          <Route path="/admin/" exact={true} component={Admin} />
          <Route path="/admin/sites/" exact={true} component={Sites} />
          <Route path="/admin/users/" exact={true} component={Users} />
          <Route path="/admin/users/:id" exact={true} component={User} />
          <Route path="/admin/events/" exact={true} component={AdminEvents} />
          <Route path="/admin/events/:id" exact={true} component={Event} />
          <Route path="/admin/utilities/" exact={true} component={Utilities} />
          <Route path="/admin/utilities/:id" component={Utility} />
          <Route path="/events/" component={Events} />
          <Route path="/billing/" exact={true} component={BillingHistory} />
          <Route path="/billing/:id" component={BillView} />
          <Route path="/pay" exact={true} component={BillPayView} />
          <Route path="/dontdoit" exact={true} component={Bryan} />
        </Switch>
      </Router>
    </div>
  );
};

export default AppRouter;
