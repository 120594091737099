import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import IUser from "../../../classes/IUser";
import Card from "../../../components/Card";
import Currency from "../../../components/Currency";

interface IProps {
  userProfile: IUser;
}

const Highlight = styled.strong`
  color: #33c3f0;
`;

const PaymentOptions = styled.div`
  display: flex;
  align-items: center;

  & > * {
    margin-right: 20px;
  }
`;

const BillSection: React.FC<IProps> = (props: IProps) => {
  const { billing } = props.userProfile;

  if (billing && billing.amount > 0) {
    return (
      <Card>
        <h3>
          You have a balance of{" "}
          <Highlight>
            <Currency>{billing.amount}</Currency>
          </Highlight>
        </h3>

        <PaymentOptions>
          <Link
            to={`/billing/${billing.cycle}`}
            className="button button-primary"
          >
            View Latest Bill
          </Link>
          <Link to="/billing">View Bills</Link>
        </PaymentOptions>
      </Card>
    );
  } else {
    return (
      <Card>
        <h3>You are up to date with all your payments.</h3>

        <PaymentOptions>
          <Link to={`/billing/`}>View Billing History</Link>
        </PaymentOptions>
      </Card>
    );
  }
};

export default BillSection;
