import firebase from "firebase";
import React from "react";
import { Link } from "react-router-dom";
import ISite from "../../../../classes/ISite";
import IUser from "../../../../classes/IUser";
import Loading from "../../../../components/Loading";

interface IState {
  data: IUser[];
  isLoading: boolean;
}
class Sites extends React.PureComponent<any, IState> {
  constructor(props: any, state: IState) {
    super(props, state);

    this.state = {
      data: [],
      isLoading: true,
    };
  }

  public async componentDidMount() {
    const dataSnap = await firebase
      .database()
      .ref("/users")
      .orderByChild("displayName")
      .once("value");

    const data: IUser[] = [];
    dataSnap.forEach((childSnapshot) => {
      const user = { ...childSnapshot.val(), key: childSnapshot.key };
      data.push(user);
    });

    this.setState({ data, isLoading: false });
  }

  public render() {
    const { data, isLoading } = this.state;

    if (isLoading) {
      return <Loading />;
    }

    return (
      <>
        <div className="row">
          <div className="one-half column">
            <h1>Users</h1>
          </div>
          <div className="one-half column align-right">
            <Link
              to={`${this.props.match.path}new`}
              className="button button-primary"
            >
              New User
            </Link>
          </div>
        </div>

        <table className="u-full-width">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>Site</th>
              <th />
            </tr>
          </thead>
          <tbody>{data && data.map(this.buildRow)}</tbody>
        </table>
      </>
    );
  }

  private buildRow = (user: IUser, index: number) => {
    return (
      <tr key={index}>
        <td>{user.displayName}</td>
        <td>{user.email}</td>
        <td>{user.phoneNumber}</td>
        <td>{user.site && (user.site as ISite).name}</td>
        <td>
          <Link to={`${this.props.match.path}${user.key}`} className="button">
            Edit
          </Link>
        </td>
      </tr>
    );
  };
}

export default Sites;
