import firebase from "firebase";
import React from "react";
import {
  OnCaptureData,
  PayPalButton,
  PaypalOptions,
} from "react-paypal-button";
import IBill from "./IBill";

interface IProps {
  bill: IBill;
}

const options = {
  buyerCountry: "CA",
  clientId:
    "AS0edwhZEG0zH9VLHA15SMS3PBUorcLb3GNJgvcFGG8bW7lDOzU0MVD5yPZqc4fKKFFzFssklJ2jH1bP",
  currency: "CAD",
  debug: true,
};

const paypalOptions: PaypalOptions = {
  clientId:
    "AS0edwhZEG0zH9VLHA15SMS3PBUorcLb3GNJgvcFGG8bW7lDOzU0MVD5yPZqc4fKKFFzFssklJ2jH1bP",
  intent: "capture",
  currency: "CAD",
  debug: true,
};

const buttonStyles = {
  layout: "vertical",
  shape: "rect",
};

const Pay: React.FunctionComponent<IProps> = ({ bill }) => {
  const success = async (response: OnCaptureData) => {
    // alert("Transaction completed by " + details.payer.name.given_name);

    console.info(response);
    const user = firebase.auth() && firebase.auth().currentUser;

    if (user) {
      const userRef = firebase.database().ref(`/users/${user.uid}/billing`);

      const batch = {
        [`/billing/users/${user.uid}/${bill.cycle}/isPaid`]: true,
        [`/billing/cycles/${bill.cycle}/lots/${bill.lot}/isPaid`]: true,
        [`/billing/users/${user.uid}/${bill.cycle}/paymentId`]: response.id,
        [`/billing/cycles/${bill.cycle}/lots/${bill.lot}/paymentId`]: response.id,
      };

      return Promise.all([
        firebase.database().ref().update(batch),
        userRef.transaction((billing) => {
          if (billing && billing.amount) {
            billing.amount -= bill.amount;
          }
          return billing;
        }),
      ]);
    }
  };

  if (bill.amount <= 0) {
    return <></>;
  }

  return (
    <>
      <h4>Pay Now</h4>
      <PayPalButton
        amount={bill.amount}
        onPaymentSuccess={success}
        paypalOptions={paypalOptions}
      />
    </>
  );
};

export default Pay;
