import firebase from "firebase";
import React, { FunctionComponent, useEffect, useState } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import styled from "styled-components";
import * as Yup from "yup";
import { deleteUser, newUser, updateUser } from "../../../../api/user";
import ISite from "../../../../classes/ISite";
import IUser from "../../../../classes/IUser";
import Loading from "../../../../components/Loading";
import { useForm } from "../../../../hooks/Form";
import Sites from "./Sites";
import BreadCrumbs from "../../../../components/BreadCrumbs";
import { toast } from "react-toastify";

const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
`;

interface IRouteMatch {
  id: string;
}

const UserSchema = Yup.object<IUser>().shape({
  name: Yup.string().min(2, "Too Short!").required("Required"),
  email: Yup.string().min(2, "Too Short!").required("Required"),
});

const UserPage: FunctionComponent<RouteComponentProps<IRouteMatch>> = (
  props
) => {
  const {
    getFieldProps,
    getFieldValue,
    getSelectProps,
    submitData,
    isSaving,
    fieldErrors,
    resetForm,
  } = useForm({
    initialValues: {
      email: "",
    },
    handleSubmit: (values) => save(values),
    validationSchema: UserSchema,
  });

  const { id } = props.match.params;

  const [breadCrumb, setBreadCrumb] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  useEffect(() => {
    (async function LoadUser() {
      if (id !== "new") {
        const userSnap = await firebase
          .database()
          .ref(`/users/${props.match.params.id}`)
          .once("value");

        const user = userSnap.val() as IUser;
        const site = (user && user.site && (user.site as ISite).id) || "";

        const formData = {
          displayName: user.displayName || "",
          email: user.email || "",
          phoneNumber: user.phoneNumber || "",
          site,
        };

        setBreadCrumb(user.displayName);
        resetForm(formData);
      } else {
        setBreadCrumb(id);
      }
      setIsLoading(false);
    })();
  }, []);

  const save = async (values: IUser): Promise<void> => {
    console.info(values);
    console.info("saving");

    if (id !== "new") {
      await updateUser(id, values);
      toast.success("User Updated");
    } else {
      const userID = await newUser(values);
      toast.success("User Created");
      props.history.replace(`/users/${userID}`);
    }
  };

  const confirmDelete = async (): Promise<void> => {
    const answer = window.confirm("Are you sure?");

    if (answer) {
      await deleteUser(id);
      toast.success("User Deleted");
      props.history.replace(`/users`);
    }
  };

  if (isLoading || isSaving) {
    return <Loading />;
  }

  return (
    <>
      <BreadCrumbs>
        <Link to="/admin/">Admin</Link>
        <div>&gt;</div>
        <Link to="/Users/">Users</Link>
        <div>&gt;</div>
        <div>{breadCrumb}</div>
      </BreadCrumbs>

      <form onSubmit={submitData}>
        <div className="row">
          <div className="six columns">
            <label>Name</label>
            <input
              className="u-full-width"
              type="text"
              placeholder="First and Last Name"
              {...getFieldProps("displayName")}
            />
            {fieldErrors.displayName && <em>{fieldErrors.displayName}</em>}
          </div>
          <div className="six columns">
            <label>Site</label>
            <Sites
              siteId={getFieldValue("site")}
              getSelectProps={getSelectProps}
            />
          </div>
        </div>
        <div className="row">
          <div className="six columns">
            <label>Email</label>
            <input
              className="u-full-width"
              type="email"
              placeholder="test@mailbox.com"
              {...getFieldProps("email")}
              disabled={id !== "new"}
            />
            {fieldErrors.email && <em>{fieldErrors.email}</em>}
          </div>
          <div className="six columns">
            <label>Phone Number</label>
            <input
              className="u-full-width"
              type="tel"
              placeholder="555-555-5555"
              {...getFieldProps("phoneNumber")}
            />
          </div>
        </div>

        <Footer>
          <button className="primary-button">Save</button>
          {id !== "new" && (
            <button className="button" type="button" onClick={confirmDelete}>
              Delete
            </button>
          )}
        </Footer>
      </form>
    </>
  );
};

export default UserPage;
