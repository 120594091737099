import firebase from "firebase";
import { useState } from "react";

interface IStorageReturn {
  isUploading: boolean;
  isError: boolean;
  isDeleting: boolean;
  error: string | null;
  upload: (path: string, file: File) => Promise<string | null>;
  remove: (filePath: string) => Promise<boolean>;
}

const useStorage = (): IStorageReturn => {
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const onError = (e: any): void => {
    setError(e);
    setIsError(true);
  };

  const upload = async (path: string, file: File): Promise<string | null> => {
    setIsUploading(true);
    try {
      const uploadTask = await firebase.storage().ref(path).put(file);
      const imageRef = firebase.storage().ref(uploadTask.metadata.fullPath);
      return await imageRef.getDownloadURL();
    } catch (e) {
      onError(e);
      console.error(e);
    }
    setIsUploading(false);
    return null;
  };

  const remove = async (filePath: string): Promise<boolean> => {
    setIsDeleting(false);
    try {
      const storageRef = firebase.storage().refFromURL(filePath);
      await storageRef.delete();
      setIsDeleting(false);
      return true;
    } catch (e) {
      setIsDeleting(false);
      return false;
    }
  };

  return { isUploading, isError, error, isDeleting, upload, remove };
};

export default useStorage;
