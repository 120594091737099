// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from "firebase/app";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import * as serviceWorker from "./serviceWorker";

// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyDsNTXTLWhfa9kyNghizSxcsDHYM7e6SeU",
  appId: "1:1030599014573:web:56c45fcc0c4b3350",
  authDomain: "barefoot-aaf66.firebaseapp.com",
  databaseURL: "https://barefoot-aaf66.firebaseio.com",
  messagingSenderId: "1030599014573",
  projectId: "barefoot-aaf66",
  storageBucket: "barefoot-aaf66.appspot.com",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
