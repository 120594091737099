import React from "react";
import ISite from "../../../classes/ISite";
import IUser from "../../../classes/IUser";
import Card from "../../../components/Card";

interface IProps {
  userProfile: IUser;
}

const SiteSection: React.FC<IProps> = (props: IProps) => {
  const { userProfile } = props;

  return (
    <Card>
      <strong>Site:</strong>{" "}
      {userProfile.site ? (userProfile.site as ISite).name : "No Site Assigned"}
    </Card>
  );
};

export default SiteSection;
