import firebase from "firebase";
import moment from "moment";
import numeral from "numeral";
import React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import styled from "styled-components";
import Card from "../../../components/Card";
import Loading from "../../../components/Loading";
import PaidLabel from "../../../components/PaidLabel";
import IBill from "./IBill";
import Pay from "./Pay";
import BreadCrumbs from "../../../components/BreadCrumbs";

interface IRouteMatch {
  id: string;
}

interface IState {
  bill?: IBill;
  isLoading: boolean;
}

class Bill extends React.PureComponent<
  RouteComponentProps<IRouteMatch>,
  IState
> {
  private ref?: firebase.database.Reference;

  constructor(props: RouteComponentProps<IRouteMatch>, state: IState) {
    super(props, state);

    this.state = {
      isLoading: true,
    };

    const { params } = this.props.match;
    const user = firebase.auth() && firebase.auth().currentUser;

    if (user) {
      const ref = `/billing/users/${user.uid}/${params.id}`;
      this.ref = firebase.database().ref(ref);
    }

    this.setBill = this.setBill.bind(this);
  }

  public async componentDidMount() {
    if (this.ref) {
      this.ref.on("value", this.setBill);
    }
  }

  public componentWillUnmount() {
    if (this.ref) {
      this.ref.off();
    }
  }

  public render() {
    const { bill, isLoading } = this.state;

    if (isLoading) {
      return <Loading />;
    }

    if (bill) {
      const startDate = moment(bill.startDate).format("ll");
      const endDate = moment(bill.endDate).format("ll");

      const beforeTax = bill.amount / (1 + (5 + 7) / 100);
      const gst = beforeTax * 0.05;
      const pst = beforeTax * 0.07;
      return (
        <>
          <BreadCrumbs>
            <Link to="/billing/">Billing</Link>
            <div>&gt;</div>
            <div>{this.props.match.params.id}</div>
            <div>
              <PaidLabel isPaid={bill.isPaid} />
            </div>
          </BreadCrumbs>

          <div className="row">
            <Card>
              <h5>
                Customer Info - Billing From&nbsp;
                <b>
                  {startDate} to {endDate}
                </b>
              </h5>
              <table className="u-full-width">
                <tbody>
                  <tr>
                    <th>Name</th>
                    <td>{bill.customer}</td>
                  </tr>
                  <tr>
                    <th>Address</th>
                    <td>{bill.address}</td>
                  </tr>
                  <tr>
                    <th>Email</th>
                    <td>{bill.email}</td>
                  </tr>
                </tbody>
              </table>

              <h5>Hydro Consumption Details</h5>
              <table className="u-full-width">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>{moment(bill.startDate).format("D MMM")}</th>
                    <th>{moment(bill.endDate).format("D MMM")}</th>
                    <th>Diff</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Readings</td>
                    <td>{bill.pastReading || 0}</td>
                    <td>{bill.currentReading}</td>
                    <td>{bill.currentReading - (bill.pastReading || 0)}</td>
                    <td>{numeral(beforeTax).format("$0,0.00")}</td>
                  </tr>
                  <tr>
                    <td colSpan={5}>&nbsp;</td>
                  </tr>
                  <tr>
                    <td colSpan={3} />
                    <th>GST</th>
                    <td>{numeral(gst).format("$0,0.00")}</td>
                  </tr>
                  <tr>
                    <td colSpan={3} />
                    <th>PST</th>
                    <td>{numeral(pst).format("$0,0.00")}</td>
                  </tr>
                  <tr>
                    <td colSpan={3} />
                    <th>Total</th>
                    <td>{numeral(bill.amount).format("$0,0.00")}</td>
                  </tr>
                </tbody>
              </table>

              <p>
                *Fortis bills Barefoot every two months, therefore, billing is
                calculated by-monthly.
              </p>
              <p>
                *Billing is calculated by each guest’s usage, divided by the
                total usage and multiplied by the total cost of the bill.
              </p>
            </Card>
          </div>
        </>
      );
    }
  }

  private setBill(snapshot: firebase.database.DataSnapshot) {
    this.setState({ bill: snapshot.val(), isLoading: false });
  }
}

export default Bill;
