import firebase from "firebase";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import * as Yup from "yup";
import Loading from "../../../components/Loading";
import { useForm } from "../../../hooks/Form";
import { toast } from "react-toastify";

interface IState {
  email: string;
  password: string;
  isLoading: boolean;
}

const LoginBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const ActionBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Centered = styled.div`
  width: 400px;
`;

const Middle = styled.div`
  display: flex;
  justify-content: center;
`;

interface IUser {
  email: string;
  password: string;
}

const PostSchema = Yup.object<IUser>().shape({
  email: Yup.string().min(2, "Too Short!").required("Required"),
  password: Yup.string().min(2),
});

const LoginScreen: React.FunctionComponent = () => {
  const { getFieldProps, submitData, isSaving, isValid } = useForm({
    initialValues: {
      email: "",
      password: "",
    },
    handleSubmit: (values) => save(values),
    validationSchema: PostSchema,
  });

  const save = async (values: IUser) => {
    const { email, password } = values;
    try {
      await firebase.auth().signInWithEmailAndPassword(email, password);
    } catch (e) {
      console.error(e);
      toast.error(e.message.toString());
    }
  };

  if (isSaving) {
    return <Loading />;
  }

  return (
    <LoginBox>
      <Centered>
        <Middle>
          <img src="/logo.png" width="276" height="141" />
        </Middle>

        <h2>Login</h2>
        <form onSubmit={submitData}>
          <div className="row">
            <label>Your email</label>
            <input
              name="email"
              type="email"
              className="u-full-width"
              placeholder="Email Address Here"
              {...getFieldProps("email")}
            />

            <label>Password</label>
            <input
              type="password"
              name="password"
              placeholder="Password Here"
              className="u-full-width"
              {...getFieldProps("password")}
            />
          </div>

          <ActionBox>
            <Link to="/reset">Forgot Password</Link>
            <input
              className="button-primary"
              type="submit"
              value="Submit"
              disabled={!isValid}
            />
          </ActionBox>
        </form>
      </Centered>
    </LoginBox>
  );
};

export default LoginScreen;
