import firebase from "firebase";
import React from "react";
import ISite from "../../../../classes/ISite";
import Loading from "../../../../components/Loading";

interface IState {
  data: ISite[];
  isLoading: boolean;
}
class Sites extends React.PureComponent<any, IState> {
  constructor(props: any, state: IState) {
    super(props, state);

    this.state = {
      data: [],
      isLoading: true,
    };
  }

  public async componentDidMount() {
    const dataSnap = await firebase
      .database()
      .ref("/sites")
      .orderByChild("name")
      .once("value");

    const data: ISite[] = [];
    dataSnap.forEach((childSnapshot) => {
      data.push(childSnapshot.val());
    });

    this.setState({ data, isLoading: false });
  }

  public render() {
    const { data, isLoading } = this.state;

    if (isLoading) {
      return <Loading />;
    }

    return (
      <>
        <h1>Sites</h1>
        <table className="u-full-width">
          <thead>
            <tr>
              <th>Name</th>
              <th>Amp</th>
              <th>User</th>
            </tr>
          </thead>
          <tbody>{data && data.map(this.buildRow)}</tbody>
        </table>
      </>
    );
  }

  private buildRow = (site: ISite, index: number) => {
    return (
      <tr key={index}>
        <td>{site.name}</td>
        <td>{site.amp}</td>
        <td>{site.user && site.user.displayName}</td>
      </tr>
    );
  };
}

export default Sites;
