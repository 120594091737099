import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import * as Yup from "yup";

export { Yup };

interface IHash<T> {
  [key: string]: T;
}

interface IProps {
  initialValues?: IHash<any>;
  handleSubmit?: (values: any) => Promise<void>;
  validationSchema?: Yup.ObjectSchema<Yup.Shape<object, any>>;
}

interface IReturnProps {
  value: any;
  onBlur: (event: FormEvent<HTMLInputElement>) => void;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export interface ISelectReturnProps {
  value: any;
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
}

export interface ITextAreaReturnProps {
  value: any;
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
}

interface IForm {
  getFieldProps: (key: string) => IReturnProps;
  getSelectProps: (key: string) => ISelectReturnProps;
  getTextAreaProps: (key: string) => ITextAreaReturnProps;
  getFieldValue: (key: string) => string | number;
  setFieldValue: (key: string, value: string | number) => void;
  clearFieldValue: (key: string) => void;
  submitData: (event: FormEvent) => Promise<void>;
  isSaving: boolean;
  isValid: boolean;
  isFormError: boolean;
  formError?: string;
  fieldErrors: IHash<string>;
  touched: IHash<boolean>;
  resetForm: (defaultValues: any) => void;
}

export const useForm = ({
  initialValues,
  handleSubmit,
  validationSchema,
}: IProps): IForm => {
  const [values, setValues] = useState<IHash<any>>(initialValues || {});
  const [touched, setTouched] = useState<IHash<boolean>>({});
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isFormError, setIsFormError] = useState<boolean>(false);
  const [formError, setFormError] = useState<string>();
  const [fieldErrors, setFieldErrors] = useState<IHash<string>>({});
  const [isValid, setIsValid] = useState<boolean>(
    validationSchema ? false : true
  );

  useEffect(() => {
    return setIsSaving(false);
  }, []);

  const validate = async () => {
    if (validationSchema) {
      setIsValid(await validationSchema.isValid(values));
      setFieldErrors({});
      try {
        await validationSchema.validate(values, { abortEarly: false });
      } catch (e) {
        const validationErrors = e as Yup.ValidationError;
        const foundErrors = validationErrors.inner.reduce(
          YupErrorsToObject,
          {}
        );

        setFieldErrors(foundErrors);
      }
    }
  };

  const YupErrorsToObject = (
    total: IHash<string>,
    error: Yup.ValidationError
  ): IHash<string> => {
    total[error.path] = error.errors[0];
    return total;
  };

  const handleUpdate = (key: string) => (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = event.currentTarget;
    setValues((previousValues: any) => ({
      ...previousValues,
      [key]: value,
    }));

    validate();
  };

  const handleSelectUpdate = (key: string) => (
    event: ChangeEvent<HTMLSelectElement>
  ): void => {
    const { value } = event.currentTarget;
    setValues((previousValues: any) => ({
      ...previousValues,
      [key]: value,
    }));

    validate();
  };

  const handleTextAreaUpdate = (key: string) => (
    event: ChangeEvent<HTMLTextAreaElement>
  ): void => {
    const { value } = event.currentTarget;
    setValues((previousValues: any) => ({
      ...previousValues,
      [key]: value,
    }));

    validate();
  };

  const handleTouched = (key: string) => (event: any): void => {
    setTouched((previousValues: any) => ({
      ...previousValues,
      [key]: true,
    }));

    validate();
  };

  const submitData = async (event: FormEvent): Promise<void> => {
    console.info(isValid);
    event.preventDefault();
    if (isValid && handleSubmit) {
      setIsSaving(true);
      try {
        await handleSubmit(values);
      } catch (e) {
        setIsSaving(false);
        setIsFormError(true);
        setFormError(e);
      } finally {
        setIsSaving(false);
      }
    }
  };

  const getFieldProps = (key: string): IReturnProps => ({
    onBlur: handleTouched(key),
    onChange: handleUpdate(key),
    value: values[key],
  });

  const getSelectProps = (key: string): ISelectReturnProps => ({
    value: values[key],
    onChange: handleSelectUpdate(key),
  });

  const getTextAreaProps = (key: string): ITextAreaReturnProps => ({
    value: values[key],
    onChange: handleTextAreaUpdate(key),
  });

  const resetForm = (defaultValues: any) => {
    console.info(defaultValues);
    setValues(() => defaultValues);
    setTouched((previousValues: any) => ({
      ...previousValues,
      ...Object.keys(defaultValues).map((key) => ({ [key]: true })),
    }));
  };

  const getFieldValue = (key: string): string | number => values[key];

  const setFieldValue = (key: string, value: string | number): void => {
    setValues((newValues) => ({
      ...newValues,
      [key]: value,
    }));
  };

  const clearFieldValue = (key: string): void => {
    values[key] = null;
  };

  return {
    clearFieldValue,
    fieldErrors,
    formError,
    getFieldProps,
    getSelectProps,
    getTextAreaProps,
    getFieldValue,
    isFormError,
    isSaving,
    isValid,
    setFieldValue,
    submitData,
    touched,
    resetForm,
  };
};
