import React from "react";
import styled, { keyframes } from "styled-components";

const SPINNER_COLOR = "#d0d0d0";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  display: inline-block;
  width: 32px;
  height: 32px;

  &:after {
    content: " ";
    display: block;
    width: 46px;
    height: 46px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid ${SPINNER_COLOR};
    border-color: ${SPINNER_COLOR} transparent ${SPINNER_COLOR} transparent;
    animation: ${rotate} 1.2s linear infinite;
  }
`;

const Layout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default () => {
  return <Layout><Spinner /></Layout>;
};
