import firebase from "firebase";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import * as Yup from "yup";
import Loading from "../../../components/Loading";
import { useForm } from "../../../hooks/Form";

interface IState {
  email: string;
  isLoading: boolean;
}

const LoginBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const ActionBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Centered = styled.div`
  width: 400px;
`;

interface IUser {
  email: string;
}

const PostSchema = Yup.object<IUser>().shape({
  email: Yup.string().min(2, "Too Short!").required("Required"),
});

const ForgotPasswordScreen: React.FunctionComponent = () => {
  const { getFieldProps, submitData, isSaving, isValid } = useForm({
    initialValues: {
      email: "",
    },
    handleSubmit: (values) => save(values),
    validationSchema: PostSchema,
  });

  const save = async (values: IUser) => {
    try {
      await firebase.auth().sendPasswordResetEmail(values.email);
    } catch (e) {
      console.error(e);
    }
  };

  if (isSaving) {
    return <Loading />;
  }

  return (
    <LoginBox>
      <Centered>
        <h1>Forgot Password</h1>

        <form onSubmit={submitData}>
          <div className="row">
            <label>Your email</label>
            <input
              name="email"
              type="email"
              className="u-full-width"
              placeholder="Email Address Here"
              {...getFieldProps("email")}
            />
          </div>

          <ActionBox>
            <Link to="/">Sign In</Link>
            <input
              className="button-primary"
              type="submit"
              value="Submit"
              disabled={!isValid}
            />
          </ActionBox>
        </form>
      </Centered>
    </LoginBox>
  );
};

export default ForgotPasswordScreen;
