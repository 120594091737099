import React from "react";
import Loading from "./Loading";

interface IProps {
  columns: number;
  isLoading: boolean;
}

export default (props: IProps) => {
  return <tr><td colSpan={props.columns}>{props.isLoading ? <Loading /> : "No Data Found"}</td></tr>;
};
