import firebase from "firebase";
import moment from "moment";
import React, { FunctionComponent, useEffect, useState } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import ISite from "../../../../classes/ISite";
import IUser from "../../../../classes/IUser";
import Loading from "../../../../components/Loading";
import LoadingRow from "../../../../components/LoadingRow";
import { IEvent } from "../../Events";

interface IRouteMatch {
  id: string;
}

const EventsPage: FunctionComponent<RouteComponentProps<IRouteMatch>> = (
  props
) => {
  const [data, setData] = useState<IEvent[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    (async function LoadLData() {
      const loadedEvents: IEvent[] = [];
      const eventSnap = await firebase
        .database()
        .ref("/events")
        .orderByChild("start")
        .startAt(new Date().getTime())
        .once("value");

      eventSnap.forEach((snapshot) => {
        loadedEvents.push({
          key: snapshot.key,
          title: snapshot.val().title,
          desc: snapshot.val().desc,
          start: new Date(snapshot.val().start),
          end: new Date(snapshot.val().end),
        });
      });

      console.info(loadedEvents);
      setData(() => loadedEvents);
      setIsLoading(false);
    })();
  }, []);

  const buildRow = (event: IEvent, index: number) => {
    return (
      <tr key={index}>
        <td>{event.title}</td>
        <td>
          {moment(event.start).format("MMMM Do YYYY, h:mm:ss a")} to{" "}
          {moment(event.end).format("MMMM Do YYYY, h:mm:ss a")}
        </td>
        <td>
          <Link to={`${props.match.path}${event.key}`} className="button">
            Edit
          </Link>
        </td>
      </tr>
    );
  };

  return (
    <>
      <div className="row">
        <div className="one-half column">
          <h1>Events</h1>
        </div>
        <div className="one-half column align-right">
          <Link to={`${props.match.path}new`} className="button button-primary">
            New Event
          </Link>
        </div>
      </div>
      <table className="u-full-width">
        <thead>
          <tr>
            <th>Event</th>
            <th>Date</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {data && data.length > 0 ? (
            data.map(buildRow)
          ) : (
            <LoadingRow isLoading={isLoading} columns={5} />
          )}
        </tbody>
      </table>
    </>
  );
};

export default EventsPage;
