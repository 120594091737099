import firebase from "firebase/app";
import moment from "moment";
import React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import Currency from "../../../components/Currency";
import Loading from "../../../components/Loading";
import LoadingRow from "../../../components/LoadingRow";
import PaidLabel from "../../../components/PaidLabel";
import IHash from "../../../utils/IHash";
import IBill from "./IBill";

interface IRouteMatch {
  id: string;
}

interface IState {
  data?: IHash<IBill>;
  isLoading: boolean;
}

class BillingHistory extends React.PureComponent<
  RouteComponentProps<IRouteMatch>,
  IState
> {
  private ref?: firebase.database.Reference;

  constructor(props: RouteComponentProps<IRouteMatch>, state: IState) {
    super(props, state);

    this.state = {
      isLoading: true
    };

    const user = firebase.auth() && firebase.auth().currentUser;

    if (user) {
      const ref = `/billing/users/${user.uid}`;
      this.ref = firebase.database().ref(ref);
    }

    this.setHistory = this.setHistory.bind(this);
  }

  public async componentDidMount() {
    if (this.ref) {
      this.ref.orderByChild("endDate").on("value", this.setHistory);
      // this.ref.orderByChild("endDate")
      //   .startAt(moment().year(2019).startOf("year").valueOf())
      //   .endAt(moment().year(2019).endOf("year").valueOf()).on("value", this.setHistory);
    }
  }

  public componentWillUnmount() {
    if (this.ref) {
      this.ref.off();
    }
  }

  public render() {
    const { data, isLoading } = this.state;
    const { path } = this.props.match;
    if (isLoading) {
      return <Loading />;
    }

    return (
      <>
        <h1>Billing History</h1>
        <table className="u-full-width">
          <thead>
            <tr>
              <th>Start</th>
              <th>End</th>
              <th>Reading</th>
              <th>Amount</th>
              <th>Paid</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {data ? (
              Object.keys(data)
                .sort()
                .reverse()
                .map(this.buildRow(path, data))
            ) : (
              <LoadingRow isLoading={isLoading} columns={5} />
            )}
          </tbody>
        </table>
      </>
    );
  }

  private setHistory(snapshot: firebase.database.DataSnapshot) {
    this.setState({ data: snapshot.val(), isLoading: false });
  }

  private buildRow = (path: string, data: IHash<IBill>) => (bill: string) => {
    return (
      <tr key={bill}>
        <td>{moment(data[bill].startDate).format("ll")}</td>
        <td>{moment(data[bill].endDate).format("ll")}</td>
        <td>{data[bill].currentReading}</td>
        <td>
          <Currency>{data[bill].amount}</Currency>
        </td>
        <td>
          <PaidLabel isPaid={data[bill].isPaid} />
        </td>
        <td>
          <Link to={`${path}${bill}`}> View</Link>
        </td>
      </tr>
    );
  };
}

export default BillingHistory;
