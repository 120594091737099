import React, { FunctionComponent } from "react";
import Currency from "../../../../components/Currency";
import PaidLabel from "../../../../components/PaidLabel";
import { IBillingCycle, IUtility } from "./Interfaces";

interface IProps {
  index: number;
  row: IUtility;
  cycle: IBillingCycle;
  isSubmitted?: boolean;
  setPaid: (index: number) => Promise<void>;
  setLotReading: (event: React.FormEvent<HTMLInputElement>) => void;
  setLotLastReading: (event: React.FormEvent<HTMLInputElement>) => void;
  calculateAmount: (row: IUtility, cycle: IBillingCycle) => number;
}

const UtilityRow: FunctionComponent<IProps> = (props) => {
  const { index, row, cycle, setPaid, calculateAmount, isSubmitted } = props;

  const amount = calculateAmount(row, cycle);
  const SiteDiff =
    (row.currentReading && row.currentReading - row.lastReading) || 0;

  const pricePer = amount > 0 ? amount / SiteDiff : 0;

  const pay = async () => {
    await setPaid(index);
  };

  return (
    <tr>
      <td>{row.name}</td>
      <td>
        {isSubmitted ? (
          row.lastReading
        ) : (
          <input
            type="number"
            name={index.toString()}
            className="u-full-width"
            value={row.lastReading}
            onChange={props.setLotLastReading}
          />
        )}
      </td>
      <td>
        {isSubmitted ? (
          row.currentReading
        ) : (
          <input
            type="number"
            name={index.toString()}
            className="u-full-width"
            value={row.currentReading}
            onChange={props.setLotReading}
          />
        )}
      </td>
      <td className="align-right">
        <Currency>{amount}</Currency>
      </td>
      <td>{pricePer.toFixed(12)}</td>

      {isSubmitted && (
        <>
          <td className="align-right">
            <PaidLabel isPaid={row.isPaid} />
          </td>
          <td className="align-right">
            {!row.isPaid && <button onClick={pay}>Mark Paid</button>}
          </td>
        </>
      )}
    </tr>
  );
};

export default UtilityRow;
