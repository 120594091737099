import firebase from "firebase";
import React, { FunctionComponent, useEffect, useState } from "react";
import ISite from "../../../../classes/ISite";
import { ISelectReturnProps } from "../../../../hooks/Form";
import IHash from "../../../../utils/IHash";

interface IProps {
  siteId: string | number;
  getSelectProps: (key: string) => ISelectReturnProps;
}

const SitesList: FunctionComponent<IProps> = ({ siteId, getSelectProps }) => {
  const [sites, setSites] = useState<ISite[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    (async function LoadUser() {
      const siteSnap = await firebase.database().ref(`/sites`).once("value");
      const site = siteSnap.val() as IHash<ISite>;
      const sites = Object.keys(site)
        .filter((key) => key === siteId || !site[key].user)
        .map((key) => {
          return {
            id: key,
            ...site[key],
          };
        });

      setSites(() => sites);
      setIsLoading(false);
    })();
  }, []);

  if (isLoading) {
    return (
      <select className="u-full-width" disabled>
        <option value="">Loading...</option>
      </select>
    );
  }

  return (
    <select className="u-full-width" {...getSelectProps("site")}>
      <option value="">No Site</option>
      {sites.map((site) => (
        <option key={site.id} value={site.id}>
          {site.name}
        </option>
      ))}
    </select>
  );
};

export default SitesList;
