import React from "react";
import IUser from "../../../classes/IUser";

import Card from "../../../components/Card";
import BillSection from "./BillMarquee";
import SiteSection from "./Site";

interface IProps {
  userProfile: IUser;
}

const HomeScreen: React.FC<IProps> = (props: IProps) => {
  const { userProfile } = props;
  return (
    <>
      <h4>Welcome, {userProfile.displayName}</h4>

      <div className="row">
        <div className="eight columns"><BillSection userProfile={userProfile} /></div>
        <div className="four columns">
          <SiteSection userProfile={userProfile} />
        </div>
      </div>
    </>
  );
};

export default HomeScreen;
