import firebase from "firebase";
import moment from "moment";
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link, RouteComponentProps } from "react-router-dom";
import { toast } from "react-toastify";
import ISite from "../../../../classes/ISite";
import BreadCrumbs from "../../../../components/BreadCrumbs";
import Loading from "../../../../components/Loading";
import { IBillingCycle, IUtility } from "./Interfaces";
import UtilityRow from "./UtilityRow";

interface IRouteMatch {
  id: string;
}

interface IState {
  cycle: IBillingCycle;
  data: IUtility[];
  isLoading: boolean;
  isNew: boolean;
  isSaving: boolean;
}

class Utility extends React.PureComponent<
  RouteComponentProps<IRouteMatch>,
  IState
> {
  constructor(props: RouteComponentProps<IRouteMatch>, state: IState) {
    super(props, state);

    this.state = {
      cycle: {
        lastReading: 0,
        currentReading: 0,
        amount: 0,
        isPaid: false,
        startDate: 0,
        endDate: 0,
      },
      data: [],
      isLoading: true,
      isNew: false,
      isSaving: false,
    };
  }

  public async componentDidMount() {
    const isNew = this.props.match.params.id === "new";

    if (isNew) {
      const infoSnap = await firebase
        .database()
        .ref(`/billing/info/`)
        .once("value");

      let cycle: IBillingCycle = {
        lastReading: 0,
        startDate: new Date().getTime(),
        endDate: new Date().getTime(),
        isPaid: false,
      };

      const info = infoSnap.val();
      let prevUtils: any = {};
      if (info) {
        cycle = {
          lastReading: info.lastReading,
          startDate: info.endDate,
          endDate: new Date().getTime(),
          isPaid: false,
        };

        const utilSnap = await firebase
          .database()
          .ref(`/billing/cycles/${infoSnap.val().currentCycle}/lots`)
          .once("value");

        prevUtils = utilSnap.val();
      }

      const rvSnap = await firebase.database().ref("sites").once("value");

      const data: IUtility[] = [];
      rvSnap.forEach((childSnapshot) => {
        const lot: ISite = childSnapshot.val();
        const utility: IUtility = {
          key: childSnapshot.key,
          name: lot.name,
          lastReading:
            childSnapshot.key &&
            prevUtils &&
            prevUtils[childSnapshot.key] &&
            prevUtils[childSnapshot.key].currentReading,
          isNew: !(
            childSnapshot.key &&
            prevUtils &&
            prevUtils[childSnapshot.key]
          ),
        };

        if (lot.user) {
          utility.ownerName = lot.user.displayName;
          utility.email = lot.user.email;
          utility.userId = lot.user && lot.user.id;
        }

        data.push(utility);
      });

      this.setState({ isNew, cycle, data, isLoading: false });
    } else {
      const infoSnap = await firebase
        .database()
        .ref(`/billing/history/${this.props.match.params.id}/`)
        .once("value");

      const dataSnap = await firebase
        .database()
        .ref(`/billing/cycles/${this.props.match.params.id}/lots`)
        .orderByChild("name")
        .once("value");

      const data: IUtility[] = [];
      dataSnap.forEach((childSnapshot) => {
        const utility = childSnapshot.val() as IUtility;
        utility.key = childSnapshot.key;

        if (utility.ownerName) {
          data.push(utility);
        }
      });

      this.setState({ isNew, cycle: infoSnap.val(), data, isLoading: false });
    }
  }

  public render() {
    const { cycle, data, isLoading, isSaving } = this.state;

    if (isLoading || isSaving) {
      return <Loading />;
    }

    return (
      <>
        <BreadCrumbs>
          <Link to="/admin/">Admin</Link>
          <div>&gt;</div>
          <Link to="/admin/utilities/">Utilities</Link>
          <div>&gt;</div>
          <div>{this.props.match.params.id}</div>
        </BreadCrumbs>
        {this.state.isNew ? (
          <button className="primary-button" onClick={this.save}>
            Save
          </button>
        ) : (
          !cycle.isSubmitted && (
            <div className="row">
              <div className="one-half column">
                <button className="button" onClick={this.update}>
                  Update
                </button>
              </div>
              <div className="one-half column align-right">
                <button
                  className="button button-primary"
                  onClick={this.sendBills}
                >
                  Send Bills
                </button>
              </div>
            </div>
          )
        )}

        <h2>Main</h2>
        <table className="u-full-width table-fixed">
          <thead>
            <tr>
              <th>Last Reading</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Current Reading</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {cycle.isSubmitted ? (
                  cycle.lastReading
                ) : (
                  <input
                    type="number"
                    name="lastReading"
                    className="u-full-width"
                    value={cycle.lastReading}
                    onChange={this.setLastReading}
                  />
                )}
              </td>
              <td>
                {cycle.isSubmitted ? (
                  moment(cycle.startDate).format("ll")
                ) : (
                  <DatePicker
                    selected={new Date(cycle.startDate)}
                    onChange={this.setStartDate}
                  />
                )}
              </td>
              <td>
                {cycle.isSubmitted ? (
                  moment(cycle.endDate).format("ll")
                ) : (
                  <DatePicker
                    selected={new Date(cycle.endDate)}
                    onChange={this.setDate}
                  />
                )}
              </td>
              <td>
                {cycle.isSubmitted ? (
                  cycle.currentReading
                ) : (
                  <input
                    type="number"
                    name="currentReading"
                    className="u-full-width"
                    value={cycle.currentReading}
                    onChange={this.setReading}
                  />
                )}
              </td>
              <td>
                {cycle.isSubmitted ? (
                  cycle.amount
                ) : (
                  <input
                    type="number"
                    name="amount"
                    className="u-full-width"
                    value={cycle.amount}
                    onChange={this.setAmount}
                  />
                )}
              </td>
            </tr>
          </tbody>
        </table>

        <h2>Lots</h2>
        <table className="u-full-width table-fixed">
          <thead>
            <tr>
              <th>Name</th>
              <th>Last Reading</th>
              <th>Current Reading</th>
              <th>Amount</th>
              <th>Price Per/Kw</th>
              {cycle.isSubmitted && (
                <>
                  <th className="align-right">Is Paid</th>
                  <th>&nbsp;</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>{data && data.map(this.buildRow(cycle.isSubmitted))}</tbody>
        </table>

        {this.state.isNew ? (
          <button className="primary-button" onClick={this.save}>
            Save
          </button>
        ) : (
          !cycle.isSubmitted && (
            <div className="row">
              <div className="one-half column">
                <button className="button" onClick={this.update}>
                  Update
                </button>
              </div>
              <div className="one-half column align-right">
                <button
                  className="button button-primary"
                  onClick={this.sendBills}
                >
                  Send Bills
                </button>
              </div>
            </div>
          )
        )}
      </>
    );
  }

  private buildRow = (isSubmitted?: boolean) => (
    row: IUtility,
    index: number
  ) => {
    return (
      <UtilityRow
        key={index}
        row={row}
        index={index}
        cycle={this.state.cycle}
        setPaid={this.setPaid}
        setLotReading={this.setLotReading}
        setLotLastReading={this.setLotLastReading}
        calculateAmount={this.calculateAmount}
        isSubmitted={isSubmitted}
      />
    );
  };

  private setStartDate = (
    date: Date,
    event: React.SyntheticEvent<any, Event>
  ): void => {
    const cycle = { ...this.state.cycle };
    cycle.startDate = date.getTime();

    this.setState(() => ({ cycle }));
  };

  private setDate = (
    date: Date,
    event: React.SyntheticEvent<any, Event>
  ): void => {
    const cycle = { ...this.state.cycle };
    cycle.endDate = date.getTime();

    this.setState(() => ({ cycle }));
  };

  private setPaid = async (index: number): Promise<void> => {
    const cycle = this.props.match.params.id;
    const data = [...this.state.data];
    const lot = { ...data[index] };

    lot.isPaid = true;
    data[index] = lot;

    this.setState(() => ({ data }));

    if (lot.userId && lot.key) {
      const userRef = firebase.database().ref(`/users/${lot.userId}/billing`);

      const batch = {
        [`/billing/users/${lot.userId}/${cycle}/isPaid`]: true,
        [`/billing/cycles/${cycle}/lots/${lot.key.toLowerCase()}/isPaid`]: true,
      };

      const amount = this.calculateAmount(lot, this.state.cycle);
      await Promise.all([
        firebase.database().ref().update(batch),
        userRef.transaction((billing) => {
          if (billing && billing.amount) {
            billing.amount -= amount;
          }
          return billing;
        }),
      ]);
    } else {
      await firebase
        .database()
        .ref(
          `/billing/cycles/${this.props.match.params.id}/lots/${lot.name}/isPaid`
        )
        .set(true);
    }
  };

  private setLastReading = (event: React.FormEvent<HTMLInputElement>): void => {
    const { value } = event.currentTarget;
    const cycle = { ...this.state.cycle };

    cycle.lastReading = parseInt(value, 10);

    this.setState(() => ({ cycle }));
  };

  private setReading = (event: React.FormEvent<HTMLInputElement>): void => {
    const { value } = event.currentTarget;
    const cycle = { ...this.state.cycle };

    cycle.currentReading = parseInt(value, 10);

    this.setState(() => ({ cycle }));
  };

  private setLotLastReading = (
    event: React.FormEvent<HTMLInputElement>
  ): void => {
    const { name, value } = event.currentTarget;
    const data = [...this.state.data];
    const index = parseInt(name, 10);

    const lot = { ...data[index] };
    lot.lastReading = parseInt(value, 10);
    data[index] = lot;

    this.setState(() => ({ data }));
  };

  private setLotReading = (event: React.FormEvent<HTMLInputElement>): void => {
    const { name, value } = event.currentTarget;

    const data = [...this.state.data];
    const index = parseInt(name, 10);

    const lot = { ...data[index] };
    lot.currentReading = parseInt(value, 10);
    data[index] = lot;

    this.setState(() => ({ data }));
  };

  private setAmount = (event: React.FormEvent<HTMLInputElement>): void => {
    const { value } = event.currentTarget;
    const cycle = { ...this.state.cycle };

    cycle.amount = parseInt(value, 10);
    this.setState(() => ({ cycle }));
  };

  private save = async (): Promise<void> => {
    const { data, cycle } = this.state;

    this.setState(() => ({ isSaving: true }));
    if (cycle.endDate) {
      const key = moment(cycle.endDate).format("YYYY-MM");

      const exists = await firebase
        .database()
        .ref(`/billing/history/${key}`)
        .once("value");

      if (exists.val()) {
        // Show error
        toast.error(`Cycle ${key} already exists!`);
      } else {
        const batch = data.reduce<any>(this.batchData(key, cycle), {});
        batch[`/billing/history/${key}/`] = cycle;

        toast.success(`Saved New Cycle ${key}`);

        await firebase.database().ref().update(batch);

        this.setState(() => ({ isNew: false }));
        this.props.history.replace(`/admin/utilities/${key}`);
      }
    }
    this.setState(() => ({ isSaving: false }));
  };

  private update = async (): Promise<void> => {
    this.setState(() => ({ isSaving: true }));
    const { data, cycle } = this.state;

    try {
      const batch = data.reduce<any>(
        this.batchData(this.props.match.params.id, cycle),
        {}
      );

      batch[`/billing/history/${this.props.match.params.id}/`] = cycle;
      await firebase.database().ref().update(batch);

      toast.success(`Updated Cycle ${this.props.match.params.id}`);
    } catch (e) {
      toast.error(`Error updating Cycle ${this.props.match.params.id}`);
    }
    this.setState(() => ({ isSaving: false }));
  };

  private sendEmails = async (): Promise<void> => {
    const cycle = this.props.match.params.id;
    const callImport = firebase.functions().httpsCallable("emailBills");
    const result = await callImport({ cycle });
    console.info(result.data);
  };

  private sendBills = async (): Promise<void> => {
    const willSend = window.confirm(
      "Are you sure? Submitting the bill will email everyone one the list and you will not be able to edit this again."
    );
    if (willSend) {
      this.setState(() => ({ isSaving: true }));

      const { id: cycleId } = this.props.match.params;
      const { cycle, data } = this.state;

      try {
        const [usersSnap, sitesSnap] = await Promise.all([
          firebase.database().ref("users").once("value"),
          firebase.database().ref("sites").once("value"),
        ]);

        const sites = sitesSnap.val();

        const batch: any = data.reduce((total: any, utility: IUtility) => {
          if (utility.key && sites[utility.key].user) {
            const site = sites[utility.key];
            const userKey = `/billing/users/${site.user.id}/${cycleId}`;

            const amount = this.calculateAmount(utility, cycle);
            const userBill = {
              address: site.name,
              amount,
              currentReading: utility.currentReading || 0,
              customer: site.user.displayName,
              email: site.user.email,
              endDate: cycle.endDate,
              isPaid: false,
              lastReading: utility.lastReading || 0,
              startDate: cycle.startDate,
              cycle: cycleId,
              lot: utility.key,
            };

            total[userKey] = userBill;

            total[`/users/${site.user.id}/billing`] = {
              amount,
              cycle: cycleId,
            };
          }
          return total;
        }, {});

        batch[
          `/billing/history/${this.props.match.params.id}/isSubmitted`
        ] = true;

        // Update billing info with latest information
        batch["/billing/info"] = {
          currentCycle: this.props.match.params.id,
          endDate: cycle.endDate,
          lastReading: cycle.currentReading,
        };
        await firebase.database().ref().update(batch);

        await this.sendEmails();
        cycle.isSubmitted = true;
        this.setState(() => ({ cycle, isSaving: false }));
        toast.success(
          `Successfully send bills for Cycle ${this.props.match.params.id}`
        );
      } catch (e) {
        console.error(e);
        this.setState(() => ({ isSaving: false }));
        console.error(e);
        toast.error(
          `Error sending bills for Cycle ${this.props.match.params.id}`
        );
      }
    }
  };

  private batchData = (id: string, cycle: IBillingCycle) => (
    total: any,
    item: IUtility
  ): any => {
    const site = { ...item };
    site.amount = this.calculateAmount(item, cycle);

    if (site.lastReading === undefined) {
      site.lastReading = 0;
    }

    if (site.currentReading === undefined) {
      site.currentReading = 0;
    }

    delete site.key;
    total[`/billing/cycles/${id}/lots/${item.key}`] = site;
    return total;
  };

  private calculateAmount = (row: IUtility, cycle: IBillingCycle): number => {
    const SiteDiff =
      (row.currentReading && row.currentReading - (row.lastReading || 0)) || 0;

    const MasterDiff =
      (cycle.currentReading &&
        cycle.currentReading - (cycle.lastReading || 0)) ||
      1;

    const percent = SiteDiff > 0 ? SiteDiff / MasterDiff : 0;
    const amount = percent * (cycle.amount || 0);

    return this.twoDigits(amount);
  };

  private twoDigits = (amount: number): number => {
    return Math.round(amount * 1e2) / 1e2;
  };
}

export default Utility;
