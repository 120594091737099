import firebase from "firebase";
import ISite from "../classes/ISite";
import IUser from "../classes/IUser";

const buildSite = async (id: string) => {
  const siteSnap = await firebase.database().ref(`/sites/${id}`).once("value");

  const site = {
    id,
    name: siteSnap.val().name,
  };

  return site;
};

export const updateUser = async (id: string, values: IUser): Promise<void> => {
  const oldUserSnap = await firebase
    .database()
    .ref(`/users/${id}`)
    .once("value");

  const oldUser: IUser = oldUserSnap.val();
  let site = null;

  // reset old site
  if (oldUser && oldUser.site && (oldUser.site as ISite).id !== values.site) {
    await firebase
      .database()
      .ref(`/sites/${(oldUser.site as ISite).id}/user`)
      .set(null);
  }

  if (values.site && (values.site as string).toString().length > 0) {
    site = await buildSite(values.site.toString());
  }

  const userData = {
    ...values,
    site,
  };

  console.info(userData);
  const batch: any = {};
  batch[`/users/${id}`] = userData;

  if (values.site && (values.site as string).toString().length > 0) {
    const siteUser = { ...values, site: null, id };
    batch[`/sites/${values.site}/user`] = siteUser;
  }

  await firebase.database().ref().update(batch);
};

export const newUser = async (values: IUser): Promise<string> => {
  const createUserCall = firebase.functions().httpsCallable("createUser");
  const id = await createUserCall({ email: values.email });

  await updateUser(id.data, values);
  return id.data;
};

export const deleteUser = async (id: string) => {
  const oldUserSnap = await firebase
    .database()
    .ref(`/users/${id}`)
    .once("value");

  try {
    const deleteUserCall = firebase.functions().httpsCallable("deleteUser");
    await deleteUserCall({ uid: id });
  } catch (e) {
    console.log("nothing to see here");
  }

  const oldUser: IUser = oldUserSnap.val();

  const batch: any = {};
  batch[`/users/${id}`] = null;

  if (oldUser.site && (oldUser.site as ISite).id) {
    batch[`/sites/${(oldUser.site as ISite).id}/user`] = null;
  }

  firebase.database().ref().update(batch);
};
