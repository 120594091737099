import React, { FunctionComponent } from "react";
import BreadCrumbs from "../../../components/BreadCrumbs";
import { Link, RouteComponentProps } from "react-router-dom";
import styled from "styled-components";

const AdminLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  & > a {
    height: 120px;
    display: block;
    width: 43%;
    border: 1px solid;
    margin: 10px;
    padding: 20px;
    border-radius: 5px;
    color: rgb(35, 35, 35);
  }
`;

const Box = styled.div``;

const Admin: FunctionComponent<RouteComponentProps> = () => {
  return (
    <AdminLayout>
      <Link to="/admin/utilities/">
        <Box>
          <h2>Utilities</h2>
          <p>Manage Billing Periods and Payment Information</p>
        </Box>
      </Link>
      <Link to="/admin/events/">
        <Box>
          <h2>Events</h2>
          <p>
            Create Events to show on the calendar and automatically email users.
          </p>
        </Box>
      </Link>
      <Link to="/admin/users/">
        <Box>
          <h2>Users</h2>
          <p>Manage Barefoot Members and their Leases</p>
        </Box>
      </Link>
      <Link to="/admin/sites/">
        <Box>
          <h2>Sites</h2>
          <p>Manage the Sites on Barefoot</p>
        </Box>
      </Link>
      <Link to="/admin/documents/">
        <Box>
          <h2>Docs</h2>
          <p>Store and upload Barefoot Docs such as lease agreements.</p>
        </Box>
      </Link>
    </AdminLayout>
  );
};

export default Admin;
