import firebase from "firebase";
import React from "react";
import IHash from "../../utils/IHash";

const Bryan: React.FunctionComponent = () => {
  const importUsers = async () => {
    const callImport = firebase.functions().httpsCallable("importUsers");
    callImport().then(result => {
      console.info(result.data);
    });
  };

  const updateRvUsers = async () => {
    const userSnap = await firebase
      .database()
      .ref("users")
      .once("value");
    const users = userSnap.val();

    const batch: IHash<string> = Object.keys(users).reduce(
      (total: IHash<string>, key: string) => {
        const user = users[key];
        if (user.site) {
          total[`/sites/${user.site.id}/user/id`] = key;
        }
        return total;
      },
      {}
    );

    await firebase
      .database()
      .ref()
      .update(batch);
  };

  return (
    <>
      <h2>Super Top Secret</h2>
      <button className="primary-button" onClick={importUsers}>
        Import Users
      </button>

      <button className="primary-button" onClick={updateRvUsers}>
        Update Sites
      </button>
    </>
  );
};

export default Bryan;
