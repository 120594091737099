import firebase from "firebase";
import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import IUser from "./classes/IUser";
import Loading from "./components/Loading";
import Router from "./screens/Authenticated/Router";
import AuthRouter from "./screens/Unauthenticated/Router";

toast.configure();
interface IState {
  isLoading: boolean;
  isAuthenticated: boolean;
  userProfile?: IUser;
}

class App extends React.Component<any, IState> {
  private authSubscription?: () => any = undefined;
  private ref?: firebase.database.Reference;

  constructor(props: any, state: IState) {
    super(props, state);

    this.state = {
      isAuthenticated: false,
      isLoading: true
    };

    this.setProfile = this.setProfile.bind(this);
  }

  public componentDidMount() {
    this.authSubscription = firebase.auth().onAuthStateChanged(async user => {
      if (user && user.uid) {
        this.ref = firebase.database().ref(`/users/${user.uid}`);
        this.ref.on("value", this.setProfile);
      } else {
        this.setState({
          isAuthenticated: false,
          isLoading: false
        });
      }
    });
  }

  public componentWillUnmount() {
    if (this.authSubscription) {
      this.authSubscription();
    }

    if (this.ref) {
      this.ref.off();
    }
  }

  public render() {
    const { isLoading, isAuthenticated, userProfile } = this.state;

    if (isLoading === true) {
      return <Loading />;
    }

    return isAuthenticated && userProfile ? (
      <Router userProfile={userProfile} />
    ) : (
      <AuthRouter />
    );
  }

  private setProfile(snapshot: firebase.database.DataSnapshot) {
    const userProfile: IUser = snapshot.val();
    this.setState({
      isAuthenticated: true,
      isLoading: false,
      userProfile
    });
  }
}

export default App;
