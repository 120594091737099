import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import Modal from "react-modal";
import firebase from "firebase";
import "react-big-calendar/lib/css/react-big-calendar.css";

const localizer = momentLocalizer(moment);

export interface IEvent {
  key?: string | null;
  start: Date;
  end: Date;
  title: string;
  desc: string;
  image?: string;
}

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    minWidth: "800px",
  },
};

const Events: React.FC = () => {
  const [isModelOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedEvent, setSelectedEvent] = useState<IEvent | null>();
  const [events, setEvents] = useState<IEvent[]>([]);

  useEffect(() => {
    (async function LoadData() {
      const loadedEvents: IEvent[] = [];
      const eventSnap = await firebase
        .database()
        .ref("/events")
        .orderByChild("start")
        .startAt(new Date().getTime())
        .once("value");

      eventSnap.forEach((snapshot) => {
        loadedEvents.push({
          title: snapshot.val().title,
          desc: snapshot.val().desc,
          start: new Date(snapshot.val().start),
          end: new Date(snapshot.val().end),
        });
      });

      console.info(loadedEvents);
      setEvents(() => loadedEvents);
    })();
  }, []);
  const handleClick = (event: any) => {
    setSelectedEvent(event);
    setIsModalOpen(true);
  };

  const closeModel = () => {
    setSelectedEvent(null);
    setIsModalOpen(false);
  };

  return (
    <>
      <Modal
        isOpen={selectedEvent !== null && isModelOpen}
        onRequestClose={closeModel}
        contentLabel="Example Modal"
        style={customStyles}
      >
        {selectedEvent && (
          <>
            <h2>{selectedEvent.title}</h2>
            <p>{selectedEvent.desc}</p>
          </>
        )}
      </Modal>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
        popup={true}
        onSelectEvent={handleClick}
      />
    </>
  );
};

export default Events;
